import axios from "axios";
import { getCookie, setCookie, hasCookie } from 'cookies-next';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// if(!hasCookie('agent_id')) {
// 	setCookie('agent_id', 'BZON');
// }

export const tmpReducerPostLogin = createAsyncThunk('tmpReducer/tmpReducerPostLogin', async () => {
	const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/login`, 
	{lang: 'en', agent_id: 'BZON', user_id: 'webhack119@gmail.com', user_pw: '12345678'},
	{
		headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
	});
	console.dir(response, {depth: Infinity})
	if(response.data?.result) {
		setCookie('login_token', response.data?.result_data.login_token);
		setCookie('user_id', response.data?.result_data.user_id);
	}

	return response.data;
});

const initialState = {
	value: 200,
	userInfo: [],
	loading: false,
	error: '',
};

export const tmpSlice = createSlice({
	name: 'tmpReducer',
	initialState,
	reducers: {
		increment: (state, action) => {
			state.value += 1;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		reset: (state) => {
			state.value = 0;
		},
	},
	extraReducers: {
		[tmpReducerPostLogin.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[tmpReducerPostLogin.fulfilled]: (state, action) => {
			state.loading = false;
			state.userInfo = action.payload;
		},
		[tmpReducerPostLogin.rejected]: (state, action) => {
			state.loading = false;
			// action.error인 것을 주의
			state.error = action.error; 
		},
	}
});

// Action creators are generated for each case reducer function
export const { increment, decrement, reset } = tmpSlice.actions;

export default tmpSlice;
