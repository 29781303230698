import { useStore } from '../redux-store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import Head from 'next/head';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// for Bootstrap
// import "bootstrap/dist/css/bootstrap.css";
/// for Bootstrap
// for Metronic
import '../styles/google-font.css';
import '../public/demo1/dist/assets/plugins/global/plugins.bundle.css';
import '../public/demo1/dist/assets/plugins/custom/prismjs/prismjs.bundle.css';
import '../public/demo1/dist/assets/css/style.bundle.css';
import '../public/demo1/dist/assets/css/themes/layout/header/base/light.css';
import '../public/demo1/dist/assets/css/themes/layout/header/menu/light.css';
/// for Metronic
// import '../styles/globals.css';
import ErrorBoundary from './client-side-error';
import Script from 'next/script';

const Cornerstone = ({ children }) => {
	return (
		<>
			<Head>
				{/* <!-- Website title --> */}
				<title>BZON</title>
				<meta charSet="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				{/* <!-- Required meta tags for responsive --> */}
				<meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no" />
				{/* page description */}
				<meta name="description" content="Generated by create next app" />
				<meta name="keywords" content="Keywords" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
				<meta name="theme-color" content="#ffffff" />
				<meta name="application-name" content="BZON" />
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content="default" />
				<meta name="apple-mobile-web-app-title" content="BZON" />
				<meta name="format-detection" content="telephone=no" />
				<meta name="mobile-web-app-capable" content="yes" />
				<meta name="msapplication-config" content="icons/browserconfig.xml" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="msapplication-tap-highlight" content="no" />
				{/* <!-- Twitter Card data --> */}
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:url" content="#" />
				<meta name="twitter:title" content="BZON" />
				<meta name="twitter:description" content="#" />
				<meta name="twitter:image" content="#" />
				<meta name="twitter:creator" content="#" />
				{/* <!-- Open Graph data --> */}
				<meta property="og:type" content="website" />
				<meta property="og:title" content="BZON" />
				<meta property="og:description" content="BZON" />
				<meta property="og:site_name" content="BZON" />
				<meta property="og:url" content="#" />
				<meta property="og:image" content="#" />
				{/* <!-- gmail verification --> */}
				<meta name="google-site-verification" content="" />
				{/* <!-- Favicon and touch icons --> */}
				<link rel="apple-touch-icon" sizes="57x57" href="images/bzon_logo.png?imwidth=57" />
				<link rel="apple-touch-icon" sizes="60x60" href="images/bzon_logo.png?imwidth=60" />
				<link rel="apple-touch-icon" sizes="72x72" href="images/bzon_logo.png?imwidth=72" />
				<link rel="apple-touch-icon" sizes="76x76" href="images/bzon_logo.png?imwidth=76" />
				<link rel="apple-touch-icon" sizes="114x114" href="images/bzon_logo.png?imwidth=144" />
				<link rel="apple-touch-icon" sizes="120x120" href="images/bzon_logo.png?imwidth=120" />
				<link rel="apple-touch-icon" sizes="144x144" href="images/bzon_logo.png?imwidth=144" />
				<link rel="apple-touch-icon" sizes="152x152" href="images/bzon_logo.png?imwidth=152" />
				<link rel="apple-touch-icon" sizes="180x180" href="images/bzon_logo.png?imwidth=180" />
				<link rel="icon" type="image/png" sizes="192x192" href="images/bzon_logo.png?imwidth=192" />
				<link rel="icon" type="image/png" sizes="32x32" href="images/bzon_logo.png?imwidth=32" />
				<link rel="icon" type="image/png" sizes="96x96" href="images/bzon_logo.png?imwidth=96" />
				<link rel="icon" type="image/png" sizes="16x16" href="images/bzon_logo.png?imwidth=16" />
				<link rel="icon" type="image/png" href="images/bzon_logo.png?imwidth=16" />
				{/* <!-- manifest for pwa --> */}
				<link rel="manifest" href="/manifest.json" />
			</Head>
			{children}
		</>
	);
};

const MyApp = ({ Component, pageProps: { ...pageProps } }) => {
	// re-creating store using pageProps
	const store = useStore(pageProps.initialReduxState);

	// this ensures your redux state is saved to persisted storage whenever it changes
	const persistor = persistStore(store, {}, function () {
		persistor.persist();
	});

	return (
		<>
			<Provider store={store}>
				<PersistGate persistor={persistor} loading={null}>
					
					{() => (
						<Cornerstone>
							<ErrorBoundary>
								<Script src="/demo1/dist/assets/plugins/global/plugins.bundle.js"></Script>
								<Component {...pageProps} />
							</ErrorBoundary>
							<ToastContainer />
						</Cornerstone>
					)}
					
				</PersistGate>
			</Provider>
		</>
	);
};

export default MyApp;
