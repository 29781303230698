import axios from "axios";
import https from "https";
import { getCookie, setCookie, hasCookie } from 'cookies-next';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// if(!hasCookie('agent_id')) {
	setCookie('agent_id', 'BZON');
// }

const initialState = {
	value: 100,
	userInfo: [],
	isLogin: false,
	myPoint: [],
	myEthAddr: [],
	myBettingHistory: [],
	myFullTransaction: [],
	myReferralNetwork: [],
	gameList: [],
	isGameList: false,
	gameUrl: [],
	isLoading: false,
	pageName: "Home",
	error: '',
};

// 주의!! extraRGetPostsTmp를 이름을 정확히 써야 extraReducers 의 해당 액션명을 정확히 찾아감
export const infoReducerPostLogin = createAsyncThunk('infoReducer/infoReducerPostLogin', async ({agentId, userId, userPwd}, { getState, dispatch }) => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/login`, 
		{lang: 'en', agent_id: agentId, user_id: userId, user_pw: userPwd},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		});
		// console.dir(response.data, {depth: Infinity})
		if(response.data?.result) {
			setCookie('login_token', response.data?.result_data.login_token);
			setCookie('user_id', response.data?.result_data.user_id);
			setCookie('agent_id', agentId);

			dispatch(infoReducerPostMyPoint());
			dispatch(infoReducerPostEthAddr());

			toastr.info('Login Success');
			$('#divLoginModal').modal('hide');
		} else {
			toastr.error(response.data?.result_msg);
		}

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoReducerPostMyPoint = createAsyncThunk('infoReducer/infoReducerPostMyPoint', async () => {
	const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/point`, 
	{lang: 'en'},
	{
		headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
	});
	// console.dir(response.data, {depth: Infinity})

	return response.data;
});

export const infoReducerPostEthAddr = createAsyncThunk('infoReducer/infoReducerPostEthAddr', async () => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/eth/get_eth_addr`, 
		{lang: 'en'},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		});
		// console.dir(response.data, {depth: Infinity})

		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoReducerPostBettingHistory = createAsyncThunk('infoReducer/infoReducerPostBettingHistory', async ({beginDate, endDate, limit=1000, offset=0}) => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/get_bet_history`, 
		{lang: 'en', begin_date: beginDate, end_date: endDate, limit, offset},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		});

		// console.dir(response.data, {depth: Infinity})
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoReducerPostFullTransaction = createAsyncThunk('infoReducer/infoReducerPostFullTransaction', async ({operatorName, user, beginDate, endDate, limit=1000, offset=0}) => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/transaction`, 
		{lang: 'en', operatorName, user, begin_date: beginDate, end_date: endDate, limit, offset},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		});

		// console.dir(response.data, {depth: Infinity})
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoReducerPostReferralNetwork = createAsyncThunk('infoReducer/infoReducerPostReferralNetwork', async () => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/member_recommender`, 
		{lang: 'en'},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		});

		// console.dir(response.data, {depth: Infinity})
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoReducerPostGameUrl = createAsyncThunk('infoReducer/infoReducerPostGameUrl', async ({platform, game_id, game_code}) => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/hub88/game_url`, 
		{lang: 'en', platform: platform, game_id: game_id, game_code: game_code},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		});

		// console.dir(response.data, {depth: Infinity})
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoReducerPostGameList = createAsyncThunk('infoReducer/infoReducerPostGameList', async ({category, product, name}) => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/hub88/game_list`, 
		{lang: 'en', category: category, product: product, name: name},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
		});

		response.data['Category List'] = response.data['category_list'];
		response.data['Product List'] = response.data['product_list'];
		response.data['Game List Category'] = response.data['game_list-category'];
		response.data['Game List Produce'] = response.data['game_list-product'];
		delete response.data['category_list'];
		delete response.data['product_list'];
		delete response.data['game_list-category'];
		delete response.data['game_list-product'];

		// console.dir(response.data, {depth: Infinity})
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoReducerPostGameCategoryAndSupplier = createAsyncThunk('infoReducer/infoReducerPostGameCategoryAndSupplier', async () => {
	try {
		const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/hub88/game_list_2`, 
		{lang: 'en'},
		{
			headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
			/** 
			 * 이렇게 해봐야 Proxy로 동작해서 결국 CERT_HAS_EXPIRED 에러가 난다.
			 * URL을 proxy를 사용하지 않고 https://www.betstation1.com/api/hub88/game_list_2 이렇게 해야 하지만
			 * 이렇게 할 경우 Cookie 가 전달되지 않아서 에러가 발생함
			*/
			// httpsAgent: new https.Agent({
			// 	rejectUnauthorized: false
			// }),
		});

		// console.dir(response.data, {depth: Infinity})
		return response.data;
	} catch (error) {
		return thunkAPI.rejectWithValue({ error: error.message });
	}
});

export const infoSlice = createSlice({
	name: 'infoReducer',
	initialState,
	reducers: {
		increment: (state, action) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state.value += 1;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		incrementByAmount: (state, action) => {
			state.value += action.payload;
		},
		reset: (state) => {
			state.value = 0;
		},
		gameUrlClear: (state) => {
			state.gameUrl = [];
		},
		setPageName: (state, action) => {
			state.pageName = action.payload;
		},
	},
	extraReducers: {
		[infoReducerPostLogin.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
		},
		[infoReducerPostLogin.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.userInfo = action.payload;
			state.isLogin = action.payload.result; 
		},
		[infoReducerPostLogin.rejected]: (state, action) => {
			state.isLoading = false;
			// action.error인 것을 주의
			state.error = action.error; 
		},

		[infoReducerPostMyPoint.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
		},
		[infoReducerPostMyPoint.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.myPoint = action.payload;
			state.isLogin = action.payload.result; 
		},
		[infoReducerPostMyPoint.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},

		[infoReducerPostEthAddr.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
		},
		[infoReducerPostEthAddr.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.myEthAddr = action.payload;
		},
		[infoReducerPostEthAddr.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},

		[infoReducerPostBettingHistory.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
		},
		[infoReducerPostBettingHistory.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.myBettingHistory = action.payload;
		},
		[infoReducerPostBettingHistory.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},
		
		[infoReducerPostFullTransaction.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
		},
		[infoReducerPostFullTransaction.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.myFullTransaction = action.payload;
		},
		[infoReducerPostFullTransaction.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},

		[infoReducerPostReferralNetwork.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
		},
		[infoReducerPostReferralNetwork.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.myReferralNetwork = action.payload;
		},
		[infoReducerPostReferralNetwork.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},

		[infoReducerPostGameUrl.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
		},
		[infoReducerPostGameUrl.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.gameUrl = action.payload;
		},
		[infoReducerPostGameUrl.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},

		[infoReducerPostGameList.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
			state.isGameList = false;
			state.gameList = [];
		},
		[infoReducerPostGameList.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.gameList = action.payload;
			state.isGameList = true;
		},
		[infoReducerPostGameList.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},

		[infoReducerPostGameCategoryAndSupplier.pending]: (state) => {
			state.isLoading = true;
			state.error = '';
			state.isGameList = false;
			state.gameList = [];
		},
		[infoReducerPostGameCategoryAndSupplier.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.gameList = action.payload;
			state.isGameList = true;
		},
		[infoReducerPostGameCategoryAndSupplier.rejected]: (state, action) => {
			state.isLoading = false;
			state.error = action.error; 
		},
	}
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, reset, gameUrlClear, setPageName } = infoSlice.actions;

export default infoSlice;
